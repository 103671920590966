import React from "react";
import { Container, Row, Col } from "react-bootstrap";
// import privacy_policy from "../shared/images/privacy_policy.png";
import metrics from "../shared/images/metrics.png";
import write from "../shared/images/write.png";

function CenterOfExcellence() {
  return (
    <>
      <div className="banner centerofexcellence-banner"></div>
      <div className="heading padding-tb-20">
        <Container>
          <h1>Center Of Excellence</h1>
          <h2>Supplier Self-Assessment And Onboarding Portal</h2>
        </Container>
      </div>
      <Container className="my-5">
        <p className="mt-3 text-justify">
          Please click the appropriate box to begin your self-assessment. If you
          are unsure about the proper assessment to complete, please ask your
          FordDirect contact.
        </p>
        <Row className="ds-flex justify-content-center">
          <Col xs={12} md={6} lg={6}>
            <a className="card-tc" href="/TSP">
              <img src={metrics} width="50px" alt="TSP" />
              <p className="margin-top--10 fw--400">
                Technology, Security and Privacy
              </p>
            </a>
          </Col>
          <Col xs={12} md={6} lg={6}>
            <a className="card-tc" href="/marketplaceonboarding">
              <img src={write} width="50px" alt="marketplaceonboarding" />
              <p className="margin-top--10 fw--400">
                The Shop Onboarding Questionnaire
              </p>
            </a>
          </Col>
        </Row>
      </Container>
    </>
  );
}

export default CenterOfExcellence;
