import React, { useState } from "react";
import { Nav, Navbar, NavDropdown, Image } from "react-bootstrap";
import Logo from "../images/logo.png";
import { Container } from "react-bootstrap";

function Header() {
  const [showCOE, setShowCOE] = useState(false);

  return (
    <Navbar collapseOnSelect expand="lg" bg="white" variant="light">
      <Container>
        <Navbar.Brand href="https://www.forddirect.com/">
          <Image
            src={Logo}
            alt="FordDirect logo"
            className="p-2"
            width="160px"
          />
        </Navbar.Brand>
        <div className="flexarea"></div>
        <Navbar.Toggle aria-controls="responsive-navbar-nav" />
        <Navbar.Collapse id="responsive-navbar-nav">
          <Nav className="ms-auto">
            <NavDropdown
              title="Center of Excellence"
              onClick={() => {
                window.location.href = "/";
              }}
              show={showCOE}
              onMouseEnter={() => setShowCOE(!showCOE)}
              onMouseLeave={() => setShowCOE(false)}
              id="centerofexcellence"
            >
              <NavDropdown.Item href="/TSP">
                Technology, Security and Privacy
              </NavDropdown.Item>
              <NavDropdown.Item href="/marketplaceonboarding">
                The Shop Onboarding Questionnaire
              </NavDropdown.Item>
            </NavDropdown>
          </Nav>
        </Navbar.Collapse>
      </Container>
    </Navbar>
  );
}

export default Header;
