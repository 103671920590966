import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import Header from "./shared/components/Header";
import Footer from "./shared/components/Footer";
import CenterOfExcellence from "./components/CenterOfExcellence";
import "./App.scss";
import TSP from "./components/TSP";
import MarketplaceOnboarding from "./components/MarketplaceOnboarding";

function App() {
  return (
    <>
      <Header />
      <Router>
        <Switch>
          <Route path="/" exact component={CenterOfExcellence} />
          <Route path="/TSP" component={TSP} />
          <Route
            path="/marketplaceonboarding"
            component={MarketplaceOnboarding}
          />
        </Switch>
      </Router>
      <Footer />
    </>
  );
}

export default App;
